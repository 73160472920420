
<!-- ----------------------------------------------------------------------------------------------------------------------

   Previous Query Searches Option Dialog Box - used primarily from the 'FinancialsWidget' tool where users can view
   TradingView designed tools displaying specifically related info on company financials.
   
   This dialog shows data saved to localStorage of searches made - history of last 25 company related searches

   <v-btn x-small fab color="red" dark ><i class="fa-thin fa-circle-xmark"></i></v-btn> 
--------------------------------------------------------------------------------------------------------------------------->
<template>
    <v-row justify="center">
      <v-dialog v-model="showDialog" scrollable max-width="425px" >
        <v-card>
          <v-card-title class="justify-center white--text text-h5 purple darken-2">
            <v-row> 
              <v-col class="pr-1" cols="2" >
                <v-btn x-small fab color="white" @click="newStockSearch()" >
                    <v-icon>fa-regular fa-square-plus</v-icon> 
                </v-btn> 
              </v-col>
                <v-col cols="8">Search History</v-col>
              <v-col class="pl-1" cols="2">
                <v-btn x-small fab outlined color="white" @click="clearEntirePrvList()" >
                    <v-icon>fa-solid fa-trash</v-icon> 
                </v-btn>     
              </v-col>
            </v-row>             
          </v-card-title>
          <v-card-subtitle class="justify-center mt-3"><b>Your Last 50 Stock Queries</b></v-card-subtitle> 
          <v-card-text style="height: 350px;">
            <v-radio-group v-model="symbol" class="small-radio" column >
                <v-radio v-for ="item in queryList" 
                                      :key ="item.ticker"
                                      :label ="' [ '+item.ticker+' ] '+item.label" 
                                      :value ="item.ticker">
                </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <!-- desktop -->
            <div v-if="isMobile === false"> 
              <v-row class="mt-1 mb-1" > 
                  <v-col cols="2">
                      <v-btn small rounded block color="green lighten-3" @click.prevent.stop="selectPrvQuery" :disabled="this.symbol == ''" >
                        <v-icon class="ml-0 mr-1">fa-solid fa-check</v-icon> Select
                      </v-btn>
                  </v-col>
                  <v-col cols="1">&nbsp;</v-col>
                  <v-col cols="2">
                      <v-btn small rounded block color="red lighten-2" @click.prevent.stop="cancelQuery" >
                        <v-icon class="ml-0 mr-1">fa-solid fa-ban</v-icon> Cancel
                      </v-btn>
                  </v-col>
                  <v-col cols="3">&nbsp;</v-col>
                  <v-col cols="2">
                    <v-btn small rounded block color="yellow darken-5"  @click.prevent.stop="removeSelectedItem()" :disabled="this.symbol == ''" >
                        <v-icon class="ml-0 mr-1">fa-regular fa-square-minus</v-icon> Remove
                    </v-btn>     
                  </v-col>
              </v-row>
            </div>
            <div v-else>
            <!-- mobile -->
              <v-row class="mt-1 mb-1" > 
                  <v-col cols="2">
                      <v-btn small block color="green lighten-3" @click.prevent.stop="selectPrvQuery" :disabled="this.symbol == ''" >Select</v-btn>
                  </v-col>
                  <v-col cols="1">&nbsp;</v-col>
                  <v-col cols="2">
                      <v-btn small block color="red lighten-2"   @click.prevent.stop="cancelQuery" >Cancel</v-btn>                 
                  </v-col>
                  <v-col cols="3">&nbsp;</v-col>
                  <v-col cols="2" class="mr-0 pr-1">
                      <v-btn small block color="yellow darken-5" @click.prevent.stop="removeSelectedItem()" :disabled="this.symbol == ''" >Remove</v-btn>                        
                  </v-col>
              </v-row>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
  // listen for and allow broadcasts to components
  import { eventBus } from '@/main'  // event bus communication for registered components
  // *** use NY Date info for unigue key to sync with server - basic security ***
  var tNYDate = new Date(new Date().toLocaleString('en', {timeZone:'America/New_York'}));  

  export default {
      data () {
        return {
            showDialog: false,
            queryList:  '',
            prvQuery:   '',
            symbol:     '',
            cikNo:      '',
            cmpName:    '',
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
            //---------- SECURITY CHECKS SENT to SERVER to execute queries ------------*
            xKey:  tNYDate.getFullYear()+(tNYDate.getMonth()+1)+(tNYDate.getDate()),
            x2Key: tNYDate.getFullYear()*(tNYDate.getMonth()+1)*(tNYDate.getDate()),          
            //-------------------------------------------------------------------------*               
        }
      },
      mounted() {
          // *** listen for Search Ticker - from parent component - [ true or false ] passed to show this dialog  ***
          eventBus.$on( "show-old-queries-dialog", this.showOldQueryDialog ); 
      },
      beforeDestroy() {
          this.$loading( true );
      },
      methods: {
         // user pressed select radio button 
         selectPrvQuery() {
            // perform query using previous selected query
            this.getCIKfromTicker( this.symbol );
         },
         cancelQuery() {
            this.showDialog = false;
         },
         refreshTabs() {
            eventBus.$emit('ticker-changed', this.symbol );  
         },
         // passed back from event bus from parent when more than 1 ticker returned
         showOldQueryDialog() {
              try {
                 this.symbol = '';     // clear selection - if any previous
                 // get query from localStorage for list before component is shown - everytime
                 this.queryList = JSON.parse( localStorage.getItem( "prvSearchList" ) );
                 // console.log( this.queryList );
              } catch( err ) {
                 // on error - remove 'prvSearchList' - will rebuild itself on next selection
                 localStorage.removeItem( "prvSearchList" );
              }
              // Here you would put something to happen when dialog opens up
              setTimeout( ()=> this.$loading( false ), 250 );  // hide spinner        
              // **** update data block - to hide or show dialog from component flag ****
              this.showDialog = true;
         },
         removeSelectedItem() {
              let xSymbol = this.symbol; // query to be removed from list
              this.$swal({
                    title: "Remove [ "+xSymbol+" ] Query?",
                    text:  "You won't be able to revert this!",
                    icon:  "question",
                    footer: "FYI: Query [ "+xSymbol+" ] Will Be Removed From List",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText:  "Cancel"
              }).then((result) => {
                    if( result.isConfirmed ) {
                      // User clicked "Yes"
                      let updateList = JSON.parse( localStorage.getItem( "prvSearchList" ) );
                      for (let [i, item] of updateList.entries()) {
                          if( item.ticker === this.symbol ) {
                             updateList.splice(i, 1); // removed from "queries"
                          }
                      }
                      // check if all items removed - close dialog
                      if( updateList && updateList.length <= 0 ) {
                           localStorage.removeItem( "prvSearchList" );
                           this.queryList  = '';
                           this.prvQuery   = '';
                           this.showDialog = false;  // close dialog
                      } else {
                          // update previous search list in localStorage - item removed
                          localStorage.setItem( "prvSearchList", JSON.stringify( updateList ) );
                          this.symbol = '';  // *** clear selection of radio button ***
                          // update component list after change
                          this.queryList = JSON.parse( localStorage.getItem( "prvSearchList" ) ); 
                          this.$swal("Deleted!", "Query [ "+xSymbol+" ] Removed.", "success");  
                      }                   
                    }
              });
         },
         clearEntirePrvList() {
              this.$swal({
                    title: "Delete List",
                    text: "All items will be removed, are you sure?",
                    icon: "question",
                    footer: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!"
              }).then((result) => {
                    if( result.isConfirmed ) {
                      // User clicked "Yes"
                      this.$swal("Deleted!", "All Previous Queries Removed.", "success");
                      // remove entire list from localStorage
                      localStorage.removeItem( "prvSearchList" );
                      this.queryList  = '';
                      this.prvQuery   = '';
                      this.showDialog = false; // close dialog
                    }
              });
         },
        /*********************************************************************************************
         * used also in Financials Component to search for CIK # from ticker symbol to pull EDGAR Info
         *********************************************************************************************/
         getCIKfromTicker( ticker ) {
            // show spinner before request
            this.$loading( true ); 
            // console.log("FYI: Calling CikNo Service host: "+searchForCik );
            // clear multiMatch List - if any previous searches in case stored
            // sessionStorage.removeItem( "multiCompanyMatchList" );
            // sessionStorage.removeItem( "lastSearchedQuery" );              
            // send request to back-end here ...needed to URI encode ticker if spaces present
            // console.log( "Prv Search Dialog - checking back-end for -> [ "+qryTicker+" ]" );
            this.axios.get( '/cikNo?ticker='+ticker,{timeout:5000,headers:{'api-ref':this.xKey,'sc-ch-nx':this.x2Key,'mode':this.mode} }).then((response) => {
                // check if not null .. then how many multible options returned from server ... this section
                // this.$loading(false);  // clear spinner - on data return
                if( response.data && response.data.length >= 1 ) {
                  // handle 1 record returned matching company name only searches
                  if( response.data.length == 1 ) {
                      //console.log("Prv Search Dialog -> found 1 match: "+response.data);
                      try {
                          // split out here to get Ticker and CikNo from selection as passed back by server query
                          // return example => { label: "Eagle Point Credit Co Inc.", value: "ECCV:1604174" }
                          this.symbol      = response.data[0].value.split(':')[0]; // ticker prefix
                          this.cikNo       = response.data[0].value.split(':')[1]; // Cik # after ':' suffix
                          this.cmpName     = response.data[0].label;               // Company Name
                          //console.log( "Search: "+ticker+" 1 Match Ticker: "+this.symbol+" Cik: "+this.cikNo );
                          // update localStorage  - to reflect regular search selection for front-end refresh
                          localStorage.setItem( "lastSearchedSymbol", this.symbol );
                          localStorage.setItem( "CikNo", this.cikNo )                           
                          localStorage.setItem( "lastSearchCompany", this.cmpName );
                          // ****** post update to all listening sub components to update themselves ******
                          //  const tkr = this.symbol;
                          //  this.$emit( "ticker-changed", "forceRerender" );  // <-- fails  ???
                          // ****** post update to all listening sub components to update themselves ******
                          // hard re-load of webpage to sync all tabs 
                          location.reload();
                      } catch( err ) {
                          this.$loading(false); // clear spinnner
                          this.$swal({ icon: "error", title: "JSON Issue", text: "Please Re-try Your Query", footer: "[ "+err+" ]" }); 
                          // fix json error - remove prvSearchList - will recreate itself on next try
                          localStorage.removeItem( "prvSearchList" );
                          // hard reload - on error ??? eventBus works sometimes ... not others ?
                          setTimeout( ()=> this.$loading( true ), 3500 );
                          setTimeout( ()=> location.reload(), 4500 );
                      }
                  } else {
                      // check for multiple Json records returned - here
                      try {
                          // check number of matching companies returned first - limit list to 50 browser memory issues
                          if( response.data.length > 50 ) {
                              this.$loading(false);
                              this.$swal({ icon: "warning", title: "Matches Exceeds Limit", text: "Too Many Matches For: [ "+ticker+" ]", footer: 'Define a more precise query: # Matches ('+response.data.length+')' }); 
                          } else {
                              // 1/10 sec delay - then remove loading indicater       
                              setTimeout( ()=> this.$loading(false), 100 );
                              // console.log("Prv Search Dialog -> found Multiple matches: "+response.data);
                              // update localStorage multi match list for child to view
                              sessionStorage.setItem( "multiCompanyMatchList", JSON.stringify( response.data ) );
                              // pass query to Multi-Match dialog - using sessionStorage
                              sessionStorage.setItem( "lastSearchedQuery",     ticker.toLocaleUpperCase() );
                              // ******* then show dialog for user to select from radio buttons matching found list *********
                              //setTimeout( ()=> eventBus.$emit("show-multiMatch-dialog", true ), 1000 );
                              console.log( "Call to Show Multi-Match Dialog for: "+ticker );
                              //*********************************************************************************************                              
                              console.log( "messages sent: for "+ticker );
                          }
                      } catch( err ) {
                          this.$loading(false);
                          this.$swal({ icon: "error", title: "JSON Issue", text: "Please Re-try Your Query", footer: "[ "+err+" ]" }); 
                          // fix json error - remove prvSearchList - will recreate itself
                          localStorage.removeItem( "prvSearchList" );                          
                      }
                  }
                } else {
                    this.$loading(false);
                    this.$swal({ icon: "warning", title: "No Matches Found", text: "Ticker Lookup Searched Failed", footer: 'Please, Enter a Valid Ticker or Company Name' }); 
                }
            }).catch( (err) => {
                // display 404 Error dialog if No CIK Number found on back-end call
                this.$loading(false);
                this.$swal.close();
                this.$swal.fire({
                  icon:   "error",
                  title:  "Search - Network Issue",
                  text:   "Ticker Lookup Service Failure, Check Network Connection.",
                  footer: "Wait a bit .. then try again"
                });
                // clear multiMatch List - if any previous searches performed
                //sessionStorage.removeItem( "multiCompanyMatchList" );
                console.log("Cik Search Error: "+ err )
            }).finally( ()=> {
                // this.$loading(false); // clear hour glass - diables to allow full page reload to sync tabs
            })
         },  
         /********************************************************************************************
          * new search button option - copied from FinancialWidget.vue 
          * need to close hosting dialog - conflicting z-index issue 
          ********************************************************************************************/
          newStockSearch() {
              this.showDialog = false;  // close this dialog and launch parent's new search method
              eventBus.$emit("show-new-stock-search-tool", "searchStockSymbols");
          }
    }
  }
</script>

<!-- removed 'scope' to apply css to radio buttons -->
<style>

.v-label theme--light {
  font-size: 9px;
  font-family: Arial, Helvetica, sans-serif
}

</style>

