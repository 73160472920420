<!----------------------------------------------------------------------------------------------------  
     Live Ticker Tape from Tradingview widgets
    [ https://www.tradingview.com/widget/ticker-tape/ ]
    [ https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js  ]
    src="js/embed-widget-ticker-tape.js"

    <%= BASE_URL%>
----------------------------------------------------------------------------------------------------->
<template>
    <div>
      <div v-if="isMobile !== true" >
          <v-container class="mt-5 mb-2 ml-0 mr-0 pa-0" fluid >
                <div class="mt-3 pt-3 ml-1">
                    <!-- Widget Card Block -->  
                    <v-card class="mx-auto my-auto flexcard" width="100%" height="25px">
                        <!-- TradingView Widget BEGIN -->
                        <div class="tradingview-widget-container">
                            <div class="tradingview-widget-container__widget"></div>
                            <div class="tradingview-widget-copyright"></div>
                                <script ref="tvScript" type="application/javascript" src="js/embed-widget-ticker-tape.js" async>
                                    {
                                        "symbols": {{ this.symbols }},
                                        "showSymbolLogo": true,
                                        "colorTheme": "dark",
                                        "isTransparent": false,
                                        "displayMode": "regular",
                                        "largeChartUrl": "https://www.stock-insight.net/symbolchart",                                
                                        "locale": {{ this.locale }}
                                    }
                                </script>
                            </div>
                            <!-- TradingView Widget END -->
                    </v-card>
                    <!-- END Widget Block-->
                </div>
          </v-container>
      </div>
      <div v-else >
          <!-- MOBILE TICKER FEED -->
          <v-container class="mt-1 mb-11 ml-0 mr-0 pa-0" fluid >
              <!-- Widget Card Block -->  
              <v-card class="mx-auto my-auto flexcard" width="100%" height="25px">
                  <!-- TradingView Widget BEGIN -->
                  <div class="tradingview-widget-container">
                      <div class="tradingview-widget-container__widget"></div>
                      <div class="tradingview-widget-copyright"></div>
                          <script type="application/javascript" src="js/embed-widget-ticker-tape.js" async>
                              {
                                  "symbols": {{ this.symbols }},
                                  "showSymbolLogo": true,
                                  "colorTheme": "dark",
                                  "isTransparent": false,
                                  "displayMode": "compact",
                                  "largeChartUrl": "https://www.stock-insight.net/symbolchart",                                
                                  "locale": {{ this.locale }}
                              }
                          </script>
                      </div>
                      <!-- TradingView Widget END -->
              </v-card>
              <!-- END Widget Block-->
          </v-container>
      </div>
  </div>
  </template>
  
  <script>
      // i18n international language support for labels - should use browser locale default settings
      // all labels are defined in src/locals folder in json files - ie: en.json for English settings
      import i18n from "@/i18n" 

      // listen for and allow broadcasts to components
      import { eventBus } from '@/main'  // event bus communication for registered components

      export default {
        data() { return {
          valid: false,
          // *** ticker feed breaks easily. wrapped locale with double quotes for TV widget accepts only valid Json
          locale: '"'+i18n.locale+'"',
          // determines on object creation if running on mobile device true or false
          isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),
          // *** following settings are designed for desktop - flipped if 'mobile' before mount ***   650           
          cardWidth:   "100%",
          widgetWidth: "100%",
          widgetHeight: 50, 
          // *** default list limited by TV to max - only 23 entries allowed ****
          symbols: [  { "proName": "FOREXCOM:SPXUSD", "title": "S&P 500" },
                      { "description": "Tesla", "proName": "TSLA" },
                      { "description": "Apple", "proName": "AAPL" },
                      { "description": "Disney", "proName": "DIS" },
                      { "description": "Nvida", "proName": "NVDA" },
                      { "description": "Amazon","proName": "AMZN" },
           ]
        }; },
        /**********************************************************************
         * on App setup - check for user localStorage ticker list or
         * grab default list from S3 bucket - store to localStorage for user
         * to make modifications - add or remove selected stock tickers
         **********************************************************************/
        beforeMount() {
            if( this.isMobile ) { this.cardWidth="100%"; }
            // get updated ticker list from S3 - if not in localStorage
            this.getLatestTickerList();
        },
        methods: {
            // get running ticker list from S3 bucket - if available 
            getLatestTickerList() {
                // check for existing live Ticker Tape in localStorge - user updateable 
                let tickerTape = localStorage.getItem("tickerTape");
                // check for atleast 5 stock tickers or grab list from S3 bucket 
                if( tickerTape !== null && tickerTape.length >= 5 ) {
                    // grab existing live ticker tape data from localStorage
                    this.symbols = JSON.parse( tickerTape );
                    //console.log("Ticker List Updated from Storage: ("+this.symbols.length+") Symbols.");   
                } else {
                    // or ... retrieve Ticker Tape from back-end call - data array list expected
                    this.axios.get('/rs/utils/document?file=liveTickerList.txt').then((response) => {
                        // console.log( response.data  );     // <- later comment out
                        if( response.data && response.data.length > 5 ) {
                            this.symbols = response.data;     // overwrite default ticker list
                            // place copy of TV Live TickerTape array in localStorage - for editing by user - default from S3 bucket
                            localStorage.setItem( "tickerTape", JSON.stringify( this.symbols ) );
                        } else {
                            // else -> store limited default tickerTape from component settings into localStorage 
                            // - waiting user modifications later
                            localStorage.setItem( "tickerTape", JSON.stringify( this.symbols ) );                            
                        }
                        console.log("Live Ticker List Updated: ("+this.symbols.length+") Symbols.");          
                    }).catch( err => { 
                        // else -> store limited default tickerTape into localStorage - waiting user modifications
                        localStorage.setItem( "tickerTape", JSON.stringify( this.symbols ) );
                        console.log( err );
                    });
                }
            },
        }
      }
  </script>