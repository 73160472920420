import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{key:_vm.componentKey,staticClass:"fill-height"},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VCard,{staticClass:"mx-auto my-auto",attrs:{"height":"100%","width":"100%","hover":""}},[_c(VCardText,{staticClass:"text--primary ma-0 pa-0"},[_c(VContainer,{staticClass:"ma-0 pa-0",attrs:{"fill-height":""}},[_c(VFlex,{attrs:{"fill-height":""}},[_c(VCard,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll.self",value:(_vm.onScroll),expression:"onScroll",modifiers:{"self":true}}],ref:"rssViewer",staticClass:"scroll",attrs:{"height":this.cardHeight-(this.isMobile?100:185)}},[_c('RssFeedViewer',{attrs:{"rssFeed":_vm.reportData}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }