import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"app"}},[_c('AppBar'),_c(VMain,[_c(VNavigationDrawer,{attrs:{"app":"","hide-overlay":"","right":"","stateless":"","no-click-animation":"","width":this.rDrawerSize,"flat":"","color":"transparent","temporary":""},model:{value:(_vm.rDrawer),callback:function ($$v) {_vm.rDrawer=$$v},expression:"rDrawer"}},[_c('AppBarRightDrawer')],1),_c('router-view')],1),_c('TickerFeed',{key:_vm.tapeComponentKey}),_c('FooterBar'),_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"rounded":"pill","color":"green","elevation":"24"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }