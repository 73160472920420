
<!--
  
    TV FUNDAMENTALS Widget TAB COMPONENT - with :key to trigger reload all components - refresh
    <v-container class="fill-height" :key="this.symbol" > 
-->
<template>
    <v-container class="fill-height" :key="componentKey" > 
        <v-layout justify-center align-center>    <!-- min-width="225" width="550" max-width="600" -->    
            <v-card id="tvFinancials" class="mx-auto my-auto" height="100%" width="100%" hover >
                <v-card-text class="text--primary ma-0 pa-0">
                    <v-container fill-height class="ma-0 pa-0" >
                        <v-flex id="printable-content" fill-height >  
                            <div v-if="isMobile">
                                <!-- MOBILE -->
                                <v-card height="735" >
                                    <!-- TradingView Widget BEGIN -->
                                    <div class="tradingview-widget-container">
                                    <div class="tradingview-widget-container__widget"></div>
                                    <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                        <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-financials.js" async>
                                                {
                                                    "colorTheme": "light",
                                                    "isTransparent": false,
                                                    "largeChartUrl": "",
                                                    "displayMode": "regular",
                                                    "width": "99%",
                                                    "height": "100%",
                                                    "largeChartUrl": "https://stock-insight.net/symbolchart",                                        
                                                    "symbol": "{{ this.symbol }}",
                                                    "locale": "{{ this.locale }}"
                                                }
                                        </script>
                                    </div>
                                    <!-- TradingView Widget END -->
                                </v-card>
                            </div>
                            <div v-else>
                                <!-- DESKTOP -->
                                <v-card  height="655" >
                                    <!-- TradingView Widget BEGIN -->
                                    <div class="tradingview-widget-container">
                                    <div class="tradingview-widget-container__widget"></div>
                                    <div class="tradingview-widget-copyright"><span class="gray-text">Track all markets on TradingView</span></div>
                                        <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-financials.js" async>
                                                {
                                                    "colorTheme": "light",
                                                    "isTransparent": false,
                                                    "largeChartUrl": "",
                                                    "displayMode": "regular",
                                                    "width": "99%",
                                                    "height": "100%",
                                                    "largeChartUrl": "https://stock-insight.net/symbolchart",                                        
                                                    "symbol": "{{ this.symbol }}",
                                                    "locale": "{{ this.locale }}"
                                                }
                                        </script>
                                    </div>
                                    <!-- TradingView Widget END -->
                                </v-card>                                
                            </div>
                        </v-flex>                 
                    </v-container>
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>  
</template>

<script>
    // i18n international language support for labels - should use browser locale default settings
    // all labels are defined in src/locals folder in json files - ie: en.json for English settings
    import i18n         from "@/i18n"  
    import { eventBus } from '@/main'  // event bus communication for registered components

    export default {
        // need to pass ticker in props to sync all child components 
        // props: { ticker: { type: String, default: '' } }, decided to sync with localStorage settings instead
        data: () => ({
            locale:   i18n.locale,
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ),             
            symbol:   "AAPL",
            cikNo:    "320193",
            errors:   {},
            // internal card height settings
            cardHeight: 750,
            componentKey: 0,
         }),
        methods: {
            // sync with localStorage settings for all tabs when called
            forceRerender() {
                this.componentKey += 1;  
                this.symbol = localStorage.getItem( "lastSearchedSymbol" );
                this.cikNo  = localStorage.getItem( "CikNo" );
            },                      
        },
        beforeMount() {
           // set spinner
           this.$loading(true);
        },
        mounted() {
           // listen for Ticker Changes - from parent component to refresh component
           //eventBus.$on( "ticker-changed", (payload) => { this.$loading(true); this.forceRerender(); setTimeout( ()=> this.$loading( false ), 500 ); }); 
           eventBus.$on( "ticker-changed", this.$loading(true), this.forceRerender(), setTimeout( ()=> this.$loading( false ), 500 ) );              
           // load initial - once shown
           this.forceRerender();
           setTimeout( ()=> this.$loading( false ), 1000 );
        },
    }
</script>

<style scoped>
 
</style>