import { render, staticRenderFns } from "./EdgarCompanySearch.vue?vue&type=template&id=3451fd22&scoped=true&"
import script from "./EdgarCompanySearch.vue?vue&type=script&lang=js&"
export * from "./EdgarCompanySearch.vue?vue&type=script&lang=js&"
import style0 from "./EdgarCompanySearch.vue?vue&type=style&index=0&id=3451fd22&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3451fd22",
  null
  
)

export default component.exports