
<!-- ----------------------------------------------------------------------------------------

   Multi Option Dialog Box - designed to handle multiple matches returned to answer query
   // sample list returned from server - passed by parent object
[ 
  { "label":"MICROSOFT CORP","value":"MSFT" },
  { "label":"ADVANCED MICRO DEVICES INC","value":"AMD" },
  { "label":"MICRON TECHNOLOGY INC","value":"MU" },
  { "label":"MICROSTRATEGY Inc","value":"MSTR" }
]
------------------------------------------------------------------------------------------->
<template>
    <v-row justify="center">
      <v-dialog v-model="showDialog" scrollable max-width="400px" >
        <v-card>
          <v-card-title class="justify-center white--text text-h5 teal darken-3">SEC Company Matches</v-card-title>
          <v-card-subtitle class="justify-center mt-3"><b>Search for</b>: [<b>{{ query }}</b>]</v-card-subtitle>          
          <v-card-text style="height: 350px;">
            <v-radio-group v-model="ticker_cikno" class="small-radio" column >
                <!-- on the back-end the ticker and Cik # are paired in each array element - for the front-end to parse out
                    like this: { "label":"MICROSTRATEGY Inc","value":"MSTR:12345" }
                       As you can see in this loop it grabs [0] for displaying, and the Cik # [1] stored in value when 
                         user selects a company from the radio buttons
                  // query 'eagle' returned matches from the servlet like this, then split accordingly:       
                         {label: "Eagle Point Credit Co Inc.", value: "ECCV:1604174"}
                         {label: "Eagle Point Credit Co Inc.", value: "ECCF:1604174"}
                         {label: "Eagle Point Credit Co Inc.", value: "ECCW:1604174"}
                         :disabled="ticker == null"
                  -->
                    <v-radio v-for ="item in multiMatchList" :key =  "item.value.split(':')[0]"
                                          :label =  "'['+item.value.split(':')[0]+']: '+item.label"
                                          :value =  "item.value" >
                    </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-1 mb-5">
            <v-btn color="blue lighten-3" @click="selectTicker" :disabled="!ticker_cikno" >Select</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red lighten-2" text @click="showMatchDialog( false )" >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
  // listen for and allow broadcasts to components
  import { eventBus } from '@/main'  // event bus communication for registered components

  //matchRecord: '{"label": "1812 Brewing Company, Inc.", "value": "KEGS:884380" }',
  export default {
      data () {
        return {
            showDialog:     false,
            ticker_cikno:   "",
            ticker:         "",
            cikNo:          "",
            multiMatchList: JSON.parse( sessionStorage.getItem( 'multiCompanyMatchList' ) ),
            cmpName:        "",
            isMobile: ( (/Mobile/i.test(navigator.userAgent)) ), 
            query: sessionStorage.getItem( 'lastSearchedQuery' ) // subtitle ticker symbol display        
        }
      },
      mounted() {
         // *** listen for Search Ticker - from parent component - [ true or false ] passed to display this dialog
         eventBus.$on( "show-multiMatch-dialog", this.showMatchDialog ); 
      },
      methods: {
         // user pressed select button
         selectTicker() {
              // show spinner before reload
              this.$loading( true ); 
              // break out values of ticker & cik from selection for settings
              this.ticker      = this.ticker_cikno.split(':')[0];   
              this.cikNo       = this.ticker_cikno.split(':')[1];
              // update localStorage  - to reflect regular search - keep tabs in sync
              localStorage.setItem( "lastSearchedSymbol", this.ticker );
              // store SEC 'cikNo' in localStorage 
              localStorage.setItem( "CikNo", this.cikNo );               
              // { "label":"MICROSTRATEGY Inc","value":"MSTR:12345" }
              // *** find company name from query match list - selected radio button *****
              const cmp = this.multiMatchList.find( item => item.value == this.ticker_cikno );
              localStorage.setItem( "lastSearchCompany", cmp.label );
              this.cmpName = cmp.label;  // company name
              // store last actual query string in 'prvSearchList' for 'previous search' options
              this.query  = sessionStorage.getItem( "lastSearchedQuery" );   // previously added to query list - rmoved - save ticker only  
              // *** add selected match to 'prvSearchList' in localStorage ***
              let prvSearches = localStorage.getItem( "prvSearchList" );
              // new entry to previous search list if not already listed ***
              let newObj = { "ticker": this.ticker, "label": this.cmpName };
              // setup saving successful search to 'prvSearchList'
              if( prvSearches ) {
                  // get current list - parsed
                  let updateList = JSON.parse( prvSearches );
                  let fTicker    = updateList.find( obj => obj.ticker === this.ticker );
                  // add last search txt - if not in search list already - store uppercase letters for comparison
                  if( fTicker ) {
                      // already defined in list - don't need to add it
                  } else {
                      // remove oldest search - the first if length is 50 searches or greater
                      if( updateList.length >= 50 ) {
                           updateList.splice( 0, 1 );   // remove oldest search element from list
                           updateList.push( newObj );   // append to end of list - new query
                      } else {
                           updateList.push( newObj );   // append new ticker to end of list
                      }
                      // update previous search list store back into localStorage for next query selections ***
                      localStorage.setItem( "prvSearchList", JSON.stringify( updateList ) );
                  }
              } else {
                  // setup new javascript array to hold list of queries - start new search list 
                  if( this.query ) {
                      localStorage.setItem( "prvSearchList", JSON.stringify( [ newObj ] ) );  
                  }                                              
              }
              // ****** post update to all listening sub components to update themselves ***********************
              // ---> does not work here ? to post an event ???  eventBus.$emit( "ticker-changed", this.ticker );  
              // close multi match dialog  -- hard reload -- should be able to send event ... but errors out
              // this.$emit( "ticker-changed", "forceRerender" );  // <-- fails  ???
              setTimeout( ()=> this.showMatchDialog( false ), location.reload(), 150 );
              // *** force window reload - to sync title ticker with selection - temp work-a-round ***
              // show spinner until page re-loads - eventBus throws a 'not a function' error - have to reload App ????
              // location.reload();
              // console.log( "User Selected this Company from Multi-List -> ticker: "+this.ticker+" cik#: "+this.cikNo );
         },
         // passed back from event bus from parent when more than 1 ticker returned
         showMatchDialog( bVisible ) {
            if( bVisible ) {
                try {
                  // get query from session for display title
                  this.query = sessionStorage.getItem( 'lastSearchedQuery' );
                  // update dialog list from session before dialog is shown to display selections
                  if( sessionStorage.getItem( "multiCompanyMatchList") != null ) {
                     this.multiMatchList = JSON.parse( sessionStorage.getItem( "multiCompanyMatchList") );
                  }
                } catch( err ) {
                    this.$loading(false);
                    this.$swal({ icon: "error", title: "JSON Issue", text: "Please Re-try Your Query", footer: "Invalid => [ "+err+" ]" }); 
                    // fix json error - remove prvSearchList - will recreate itself on next try
                    localStorage.removeItem( "prvSearchList" );
                }
            }
            // **** update data block - to hide or show dialog ****
            this.showDialog = bVisible;
            //  delay - then remove loading indicater       
            setTimeout( ()=> this.$loading(false), 250 );
            //console.log("Multi-Match Dialog Show ... "+bVisible);
         },
    }
  }
</script>

<!-- removed 'scope' to apply css to radio buttons -->
<style>

.v-label theme--light {
  font-size: 9px;
  font-family: Arial, Helvetica, sans-serif
}

</style>

